// Set basePath
var rootURL = $('body').data('rooturl');
var plugin_path = rootURL + 'assets/plugins/';

jQuery(document).ready(function ($) {
    // splash screen
    if ($('.splash').length > 0) {
        centered();
        var isshow = sessionStorage.getItem('isshow');
        if (isshow == null) {
            sessionStorage.setItem('isshow', 1);

            $('.splash').show().stop(true, true).delay(2000).fadeOut(2000);
        }
    }

    // google map config
    if ($('.map').length > 0) {

        $('.map').each(function () {
            //set vars
            var id = '#' + $(this).attr('id');
            var lat = $(this).data('lat');
            var lng = $(this).data('lng');
            var title = $(this).data('maptitle');

            var style = [
                {
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers": [
                        {visibility: "off"}
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "labels.icon",
                    "stylers": [
                        {"visibility": "off"}
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        {"saturation": 1},
                        {"gamma": 1},
                        {"visibility": "off"},
                        {"hue": "#e6ff00"}
                    ]
                },
                {
                    "elementType": "geometry",
                    "stylers": [
                        {"saturation": -100}
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "elementType": "labels",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "labels",
                    "stylers": [
                        {"saturation": 1},
                        {"gamma": 1},
                        {"visibility": "simplified"},
                        {"color": "#999999"}
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "labels",
                    "stylers": [{
                        "visibility": "off"
                    }]
                }
            ];

            var zoom = 14;

            if (window.width < 480) {
                zoom = 12;
            }

            var map = new GMaps({
                div: id,
                lat: lat,
                lng: lng,
                center: ({lat: lat, lng: lng}),
                scrollwheel: false,
                gestureHandling: 'cooperative',

                // SET THE MAP STYLE & ZOOM LEVEL
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                zoom: zoom,
                disableDefaultUI: true,

                // SET THE BACKGROUND COLOUR
                backgroundColor: "#eeeeee",

                // REMOVE ALL THE CONTROLS EXCEPT ZOOM
                panControl: false,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                overviewMapControl: false,
                zoomControlOptions: {
                    style: google.maps.ZoomControlStyle.SMALL
                }
            });

            map.setOptions({styles: style});

            var marker = map.addMarker({
                lat: lat,
                lng: lng,
                title: title
            });
        });

    }

    if ($('.splash').length > 0) {
        $(window).resize(function () {
            centered();
        });
    }

    // Add helperclasses to navbar toggle
    var navbar = $('.navbar-collapse');
    var mixSection = $('#project-tiles');
    var navbarToggle = $('.navbar-toggle');
    var hider = $('.hider');

    navbar.on('show.bs.collapse', function () {
        navbarToggle.addClass('open');
        if (mixSection.length > 0) {
            var height = mixSection.outerHeight() + "px";
            mixSection.css({"height": height});
        }
        hider.show();
    });

    navbar.on('hide.bs.collapse', function () {
        navbarToggle.removeClass('open');
        hider.hide();
    });

    if ($('.more-projects-container').length > 0) {
        showToolbox();
        showCarousel();
    }

    adjustBlockMargins();

    // localStorage.removeItem("currentProjectCategory");

    //set localStorage outside of project category Filter
    $('.cat-check-link').on('click', function () {
        if (localStorage.getItem("currentProjectCategory") === null) {
            localStorage.setItem("currentProjectCategory", "single");
        }
    });
});

$(window).on('resize', function () {
    adjustBlockMargins();
});

function centered() {
    // Center Logo
    var logoHeight = $('.splash-logo').outerHeight();
    var screenHeight = $(window).height();
    var margin = (screenHeight - logoHeight - 40) / 2;
    $('.splash-logo').css("margin-top", margin);
}

// Hide navbar on scroll down and show on scroll up
var didScroll;
var lastScrollTop = 0;
var delta = 80;
var navbarHeight = $('header').outerHeight();

$(window).scroll(function () {
    didScroll = true;
});

setInterval(function () {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

// set vars
var toolboxWrapper = $('.toolbox-wrapper');
var toolbox = $('.toolbox');
var toolboxLink = $('.toolbox-link');
var toTop = $('#toTop');
var moreProjectsContainer = $('.more-projects-container');
var xhr = null;

// Show/hide navbar
function hasScrolled() {
    var st = $(this).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta)
        return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight) {
        // Scroll Down
        $('header').removeClass('nav-down').addClass('nav-up');
    } else {
        // Scroll Up
        if (st + $(window).height() < $(document).height()) {
            $('header').removeClass('nav-up').addClass('nav-down');
        }
    }

    lastScrollTop = st;
}

// Show carousel
function showCarousel() {
    $('.load-carousel').on('click', function (e) {
        e.preventDefault();
        var $this = $(this);
        var carouselContainer = $('.carousel-container');
        var inner = $('.carousel-inner');
        var activeElement = "";
        var followingElements = "";
        var windowHeight = $(window).height();
        var elements = $('.carousel-image');

        var imgHeight = (windowHeight);

        $this.find('img').addClass('first');

        for (var i = 0; i < elements.length; i++) {
            var el = $(elements[i]);

            if (el.hasClass('first')) {
                activeElement = '<div class="item active"><img src="' + el.attr('src') + '" alt="" class="img-responsive center-block"></div>';
            } else {
                followingElements += '<div class="item"><img src="' + el.attr('src') + '" alt="" class="img-responsive center-block"></div>';
            }
        }

        inner.html(activeElement);
        inner.append(followingElements);

        // activate swipe function
        $(".carousel").swiperight(function () {
            $(this).carousel('prev');
        });
        $(".carousel").swipeleft(function () {
            $(this).carousel('next');
        });

        $('.item').css('height', imgHeight);

        carouselContainer.fadeIn('slow');

        $('.closer').on('click', function (e) {
            e.preventDefault();
            carouselContainer.fadeOut('fast');
            inner.html('');
            $('.first').removeClass('first');
        });

    });
}

// Show toolbox
function showToolbox() {
    if ($('.more-projects-container').is(':hidden')) {
        $(window).on('scroll', function () {
            var scrollHeight = $(document).height();
            var scrollPosition = $(window).height() + $(window).scrollTop();

            if (scrollPosition > scrollHeight - 80) {
                toolboxWrapper.css('background-color', '#ffffff');
                toolbox.show();
                toolboxLink.on('click', function (e) {
                    e.preventDefault();

                    // put data in vars
                    var firstSegment = $(this).data('first-segment');
                    var currentProjectID = $(this).data('current-project-id');
                    var ajaxUrl = "../ajax/getMoreProjectsFromSameCat/" + currentProjectID;

                    if( xhr != null ) {
                        xhr.abort();
                        xhr = null;
                    }

                    xhr = $.ajax({
                        method: "GET",
                        url: ajaxUrl,
                        dataType: 'html',
                        success: function (data) {
                            xhr.abort();
                            showSimilarProjects(data, firstSegment);
                        },
                        error: function () {
                            console.log("Error");
                        }
                    });
                });
            } else {
                toolboxWrapper.css('background', 'transparent');
                toolbox.hide();
            }
        });
    }
}

// Show similar projects
function showSimilarProjects(data, firstSegment) {
    moreProjectsContainer.html(data);
    moreProjectsContainer.css('margin-top', '45px');
    moreProjectsContainer.show();

    $('.all-projects-link').attr('href', '/' + firstSegment);

    toolboxWrapper.css('background-color', 'transparent');
    toolboxLink.hide();

    var distance = $('.more-projects-container').offset().top;

    var deferred = new $.Deferred();
    $('html, body').animate({
        scrollTop: distance
    }, {
        complete: function () {
            deferred.resolve();
            setTimeout(function () {
                localStorage.removeItem("currentProjectCategory");
            }, 3000);
        },
        duration: 250
    });
    return deferred;
}

// Adjust block margins on tablet and mobile views
function adjustBlockMargins() {
    $('.distance-row').each(function () {
        var $this = $(this);

        var topOriginal = parseInt($this.data('top'));
        var bottomOriginal = parseInt($this.data('bottom'));
        var top = "";
        var bottom = "";

        var width = $(window).width();

        if (width <= 480) {
            top = (topOriginal / 4);
            bottom = (bottomOriginal / 4);
            $this.css('margin-top', top + 'px');
            $this.css('margin-bottom', bottom + 'px');
        } else if (width < 1024) {
            top = (topOriginal / 2);
            bottom = (bottomOriginal / 2);
            $this.css('margin-top', top + 'px');
            $this.css('margin-bottom', bottom + 'px');
        } else if (width >= 1024) {
            $this.css('margin-top', topOriginal + 'px');
            $this.css('margin-bottom', bottomOriginal + 'px');
        }


    });
}
